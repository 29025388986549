/*
  ======== This is an autogenerated file. DO NOT EDIT ==================
  To add more styles, do so in libs/common-ui/styles
*/
/* @import url("../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-RegularItalic.woff2')
      format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Thin.woff2') format('woff2');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-ThinItalic.woff2')
      format('woff2');
    font-weight: 100;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-SemiboldItalic.woff2')
      format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-MediumItalic.woff2')
      format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-LightItalic.woff2')
      format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Extrabold.woff2')
      format('woff2');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-ExtraboldItalic.woff2')
      format('woff2');
    font-weight: 800;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-Bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Beatrice';
    src: url('../assets/fonts/Beatrice/Beatrice-BoldItalic.woff2')
      format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }

  /*Druk Family*/
  @font-face {
    font-family: 'Druk';
    src: url('../assets/fonts/Druk-Bold/Druk-Bold-Web.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  /* Gellix family */
  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-LightItalic.eot');
    src: local('Gellix Light Italic'), local('Gellix-LightItalic'),
      url('../assets/fonts/GELLIX/Gellix-LightItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-LightItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-LightItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-ExtraBold.eot');
    src: local('Gellix ExtraBold'), local('Gellix-ExtraBold'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBold.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBold.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBold.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-SemiBold.eot');
    src: local('Gellix SemiBold'), local('Gellix-SemiBold'),
      url('../assets/fonts/GELLIX/Gellix-SemiBold.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-SemiBold.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-RegularItalic.eot');
    src: local('Gellix Regular Italic'), local('Gellix-RegularItalic'),
      url('../assets/fonts/GELLIX/Gellix-RegularItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-RegularItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-RegularItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-ThinItalic.eot');
    src: local('Gellix Thin Italic'), local('Gellix-ThinItalic'),
      url('../assets/fonts/GELLIX/Gellix-ThinItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-ThinItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-ThinItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-Bold.eot');
    src: local('Gellix Bold'), local('Gellix-Bold'),
      url('../assets/fonts/GELLIX/Gellix-Bold.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-Bold.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-Bold.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-ExtraBoldItalic.eot');
    src: local('Gellix ExtraBold Italic'), local('Gellix-ExtraBoldItalic'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBoldItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBoldItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBoldItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-ExtraBoldItalic.ttf')
        format('truetype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-Regular.eot');
    src: local('Gellix Regular'), local('Gellix-Regular'),
      url('../assets/fonts/GELLIX/Gellix-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-Regular.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-Regular.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-Medium.eot');
    src: local('Gellix Medium'), local('Gellix-Medium'),
      url('../assets/fonts/GELLIX/Gellix-Medium.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-Medium.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-Medium.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-SemiBoldItalic.eot');
    src: local('Gellix SemiBold Italic'), local('Gellix-SemiBoldItalic'),
      url('../assets/fonts/GELLIX/Gellix-SemiBoldItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-SemiBoldItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-SemiBoldItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-Light.eot');
    src: local('Gellix Light'), local('Gellix-Light'),
      url('../assets/fonts/GELLIX/Gellix-Light.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-Light.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-Light.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-BoldItalic.eot');
    src: local('Gellix Bold Italic'), local('Gellix-BoldItalic'),
      url('../assets/fonts/GELLIX/Gellix-BoldItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-BoldItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-BlackItalic.eot');
    src: local('Gellix Black Italic'), local('Gellix-BlackItalic'),
      url('../assets/fonts/GELLIX/Gellix-BlackItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-BlackItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-BlackItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-Thin.eot');
    src: local('Gellix Thin'), local('Gellix-Thin'),
      url('../assets/fonts/GELLIX/Gellix-Thin.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-Thin.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-Thin.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-Black.eot');
    src: local('Gellix Black'), local('Gellix-Black'),
      url('../assets/fonts/GELLIX/Gellix-Black.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-Black.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-Black.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gellix';
    src: url('../assets/fonts/GELLIX/Gellix-MediumItalic.eot');
    src: local('Gellix Medium Italic'), local('Gellix-MediumItalic'),
      url('../assets/fonts/GELLIX/Gellix-MediumItalic.eot?#iefix')
        format('embedded-opentype'),
      url('../assets/fonts/GELLIX/Gellix-MediumItalic.woff2') format('woff2'),
      url('../assets/fonts/GELLIX/Gellix-MediumItalic.woff') format('woff'),
      url('../assets/fonts/GELLIX/Gellix-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  html {
    font-family: Gellix, system-ui, sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    @apply font-gellix;
  }
}

@layer components {
  button {
    @apply outline-none cursor-pointer border-none;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.drawer {
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  &.inactive {
    @apply translate-x-[115%];
  }
}
.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  @apply bg-transparent;
}

.rdw-option-wrapper {
  img {
    @apply !w-[10px] !h-[10px];
  }
}

.rdw-dropdown-wrapper {
  span {
    @apply !text-xs;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.two {
  @apply !flex;
  > * {
    width: 50% !important;
  }
}
