html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.christmas-toast .Toastify__close-button {
  color: black !important;
}

.unzerInput {
  height: 50px;
  @apply duration-300 rounded-md border-[1px] flex-1 appearance-none w-full py-3 px-4 bg-tertiary-10 text-primary placeholder:text-primary-50 shadow-sm text-base outline-0 border-primary-25 focus:bg-tertiary-25 focus:border-primary disabled:bg-tertiary-10  disabled:border-primary-25 text-[10px] xl:text-base lg:text-base md:text-base placeholder:text-[10px] xl:placeholder:text-base lg:placeholder:text-base md:placeholder:text-base;
}

.unzerInput > input {
  height: 100%;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #919c8b;
  font-size: 16px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #4ca520;
  box-shadow: #919c8b;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #fff;
  stroke-linecap: round;
}

.CircularProgressbar {
  width: 70%;
  vertical-align: middle;
}

.unzerSandboxNotify {
  display: none;
  visibility: hidden;
}

#upl-optin {
  @apply font-gellix text-xs leading-none ml-3 mt-2;
}

.unzerUI {
  @apply font-gellix text-xs;
}

.unzerUI.checkbox input[type="checkbox"],
.unzerUI.checkbox input[type="radio"] {
  cursor: pointer;
  height: 10px;
  left: 0;
  opacity: 0 !important;
  outline: none;
  padding: 0;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 3;
}
