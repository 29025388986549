.button,
.label {
  svg {
    font-size: inherit;
    color: inherit;
  }
}

.button-primary-enabled {
  background-color: black;
  color: white;
}

.button-disabled {
  background-color: gray;
  color: white;
}
